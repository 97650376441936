import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const HomeTallersPage = ({
  data,
  idiomes = [],
  categories = [],
  headed = false,
  loadMore = false,
}) => {
  const filteredTallersByCat = !categories.length
    ? data.edges
    : data.edges.filter(({ node: taller }) =>
        taller.categoria
          .map(cat => cat.slug)
          .find(c => categories.indexOf(c) !== -1)
      )
  const filteredTallersByCatAndLang = !idiomes.length
    ? filteredTallersByCat
    : filteredTallersByCat.filter(({ node: taller }) =>
        taller.idioma
          .map(idioma => idioma.slug)
          .find(i => idiomes.indexOf(i) !== -1)
      )

  // const filteredTallers = !!categories.length ? data.edges : data.edges.filter( ({ node: taller }) =>
  //   // taller.idioma.filter(i => idiomes.includes(i) ) &&
  //   !!taller.categoria.map( cat => cat.slug ).filter( c => categories.indexOf( c ) !== -1 ).length
  // )
  if (!filteredTallersByCatAndLang.length) {
    return <h1>no hem trobat cap taller amb aquestes característiques</h1>
  }
  return (
    <div id="portfolio" className="portfolio">
      <div className="container">
        {headed && (
          <div
            id="portfolio-heading"
            className="portfolio-heading section-heading"
          >
            <Link className="home-link" to="/tallers">
              Els nostres tallers
            </Link>
          </div>
        )}
        <div className="row">
          {filteredTallersByCatAndLang.map(({ node: taller }) => (
            <FitxaTaller key={taller.id} taller={taller} />
          ))}
          {loadMore && <LoadMore loadmore={loadMore} />}
        </div>
      </div>
    </div>
  )
}

const LoadMore = ({ loadmore }) => (
  <div key="load-more" className="column column-fit col3">
    <div
      className="portfolio-item dark  portfolio-item-has-excerpt"
      style={{ color: "white !important", margin: 0 }}
    >
      <Link to={`/tallers`}>
        <div
          className="portfolio-item-overlay"
          style={{ backgroundColor: "grey" }}
        />
        <h3 className="portfolio-item-title">Més tallers</h3>
        <div className="portfolio-item-description">
          <p>Descobreix tota la nostra oferta.</p>
        </div>
        <Img
          fixed={loadmore.fixed}
          alt={loadmore.title}
          className="portfolio-gatsby-image"
          style={{
            filter: `brightness(0.8)`,
          }}
        />
      </Link>
    </div>
  </div>
)

const FitxaTaller = ({ taller }) => (
  <div key={taller.id} className="column column-fit col3">
    <div
      className="portfolio-item dark  portfolio-item-has-excerpt"
      style={{ color: "white !important", margin: 0 }}
    >
      <Link to={`/tallers/${taller.slug}`}>
        <div
          className="portfolio-item-overlay"
          style={{ backgroundColor: taller.color || "grey" }}
        />
        <h3 className="portfolio-item-title">{taller.name}</h3>
        <div className="portfolio-item-description">
          <p>{taller.short_description}</p>
        </div>
        <Img
          fixed={taller.image.fixed}
          alt={taller.image.title}
          className="portfolio-gatsby-image"
          style={{
            filter: `brightness(${taller.imageFilter / 100})`,
          }}
        />
      </Link>
    </div>
  </div>
)
export default HomeTallersPage
