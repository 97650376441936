import React from "react"
import { graphql } from "gatsby"
import HomeTallersPage from "../components/index/tallers"
import Layout from "../components/layout"
import SEO from "../utils/seo"

function TallersFilters({ data }) {
  const {
    // allContentfulCategoriaTaller,
    // allContentfulIdiomaDeTaller,
    allContentfulTallers,
  } = data

  // const [selectedCats, setSelectedCats] = useState([])
  // const [selectedLangs, setSelectedLangs] = useState([])

  // const resetFields = () => {
  //   setSelectedCats([])
  //   setSelectedLangs([])
  // }
  return (
    <Layout title="Els nostres tallers" parallax={false}>
      <SEO title="Tallers" />
      {/* <div style={{ margin: "60px auto", width: '100%' }}>
          <Dropdown
            placeholder="Categoria"
            fluid
            multiple
            search
            selection
            style={{ maxWidth: 400 }}
            value={selectedCats}
            options={allContentfulCategoriaTaller.edges.map(
              ({ node: categoria }) => ({
                key: categoria.id,
                text: categoria.nom,
                value: categoria.slug,
              })
            )}
            onChange={(e, { value }) => setSelectedCats(value)}
          />
          <Dropdown
            placeholder="Idioma"
            fluid
            multiple
            search
            selection
            style={{ maxWidth: 400 }}
            value={selectedLangs}
            options={allContentfulIdiomaDeTaller.edges.map(
              ({ node: idioma }) => ({
                key: idioma.id,
                text: idioma.nom,
                value: idioma.slug,
              })
            )}
            onChange={(e, { value }) => setSelectedLangs(value)}
          />
        { (selectedCats.length || selectedLangs.length ) ? (
          <Button onClick={resetFields}>Reset</Button>
        ) : null 
        }
      </div> */}
      <HomeTallersPage
        data={allContentfulTallers}
      // categories={selectedCats}
      // idiomes={selectedLangs}
      />
    </Layout>
  )
}
export default TallersFilters

export const pageQuery = graphql`
  query TallersQuery {
    # allContentfulCategoriaTaller {
    #   edges {
    #     node {
    #       id
    #       nom
    #       slug
    #     }
    #   }
    # }
    # allContentfulIdiomaDeTaller {
    #   edges {
    #     node {
    #       id
    #       nom
    #       slug
    #     }
    #   }
    # }
    allContentfulTallers(limit: 100 ,sort: {order: ASC, fields: order}) {
      edges {
        node {
          id
          name
          short_description
          color
          slug
          imageFilter
          categoria {
            slug
          }
          idioma {
            slug
          }
          image {
            title
            # file {
            #   url
            # }
            fixed (width: 385, height: 255) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`
